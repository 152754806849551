




































































import { Component, Vue } from 'vue-property-decorator';
import myHeader from '@/components/Header.vue';
import myFooter from '@/components/Footer.vue';
import { getLiveCourse } from '@/api/liveCourse/liveCourse.ts';

@Component({
    components: {
        myHeader,
        myFooter
    }
})
export default class MyExam extends Vue {
  // 验证码展示隐藏
  isShow = true
  data = {
      // eslint-disable-next-line @typescript-eslint/camelcase
      semester_id: 0,
      student: {
          stu_id: '',
          phone: ''
      }
  };

  // 验证码
  verifyCode = ''
  code = ''
  num = 1 // 验证码请求参数
  loading = true;
  CourseData = [{
      id: 0
  }]
  // CourseData = [{
  //     // eslint-disable-next-line @typescript-eslint/camelcase
  //     crs_id: 0,
  //     // eslint-disable-next-line @typescript-eslint/camelcase
  //     crs_name: '',
  //     // eslint-disable-next-line @typescript-eslint/camelcase
  //     stu_exam: {},
  //     // eslint-disable-next-line @typescript-eslint/camelcase
  //     exm_start: '',
  //     // eslint-disable-next-line @typescript-eslint/camelcase
  //     exm_end: '',
  //     // eslint-disable-next-line @typescript-eslint/camelcase
  //     ques_id: 0,
  //     is_sign: '',
  //     isShow: false
  // }];

  // 当前时间
  nowTime = 0;
  // 考试按钮 状态 样式
  buttonArr = [{
      buttonClass: '',
      buttonMsg: ''
  }];

  created() {
      this.getData();
  }

  // 方法
  getData() {
      const time = new Date();
      this.nowTime = time.getTime();
      // eslint-disable-next-line @typescript-eslint/camelcase
      this.data.semester_id = parseInt(localStorage.getItem('semesterId') as string);
      this.data.student = JSON.parse(localStorage.getItem('student') as string);
      getLiveCourse(this.data).then((res: any) => {
          if (res.code === 200) {
              console.log(res);
              this.CourseData = res.data;
          } else {
              this.$message.error(res.error);
          }
          this.loading = false;
      }).catch((error: any) => {
          console.log(error);
      });
  }

  // 渲染时自动调用1转换01
  courseTitleValue(index: number) {
      index++;
      return index >= 10 ? index.toString() : '0' + index;
  }

  goExaminationAnswer(index: number) {
      console.log(index);
      this.$message.success('我正在进入直播间！');
      this.$router.push({
          path: '/liveVideo',
          query: {
              id: this.CourseData[index].id.toString()
          }
      });
  }
}
